import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import $ from "jquery";
import './App.css';
import Home from './pages/Home';
import Match from './pages/Match';
import MatchAnimals from './pages/Match_animals';
import MatchShapes from './pages/Match_shapes';
import Info from './pages/Info';
import InfoYearFiles from './pages/InfoYearFiles';
import Error from './pages/Error';
import MatchWords from './pages/Match_words';
import Pexeso from './pages/Pexeso';
import { config } from './util/constants';
// import XmasMS from './pages/XmasMS';
// import XmasZS from './pages/XmasZS';
import MatchPenguins from './pages/Match_penguins';
// import Maths from './pages/Maths';
// import CatMaths from './pages/Maths_cats';
import MatchChosen from './pages/Match_chosen';

export interface UserContext {
  user: User | null
  loginUser: (username: string, password: string) => Promise<boolean>
  logoutUser: () => void
}

export interface User {
  username: string
  fullName: string
}

export interface FileInfo {
  fileId: string
	fileName: string
	fileSize: number
  fileType: string
}

export enum FolderType {
  public = "publicFolder",
  private = "privateFolder"
}

export const UserContext = React.createContext<UserContext>({} as UserContext);

const baseUrl = config.API_URL;

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    window.addEventListener("dragover", handleDragOver, false);
    window.addEventListener("drop", handleDrop, false);

    document.addEventListener('pointerdown', handleDragStart);
    document.addEventListener('pointermove', handleDrag);
    document.addEventListener('pointerup', handleDragStop);
    
    return (() => {
      window.addEventListener("dragover", handleDragOver, false);
      window.addEventListener("drop", handleDrop, false);

      document.removeEventListener('pointerdown', handleDragStart);
      document.removeEventListener('pointermove', handleDrag);
      document.removeEventListener('pointerup', handleDragStop);
    });
  }, []);

  let draggedEl: HTMLElement | null;

  const handleDragStart = (e: any) => {
    if (!e.target || !e.target.matches || !e.target.matches('.draggable')) {
      return;
    }

    console.log('drag start');

    const el = $(e.target as HTMLElement);
    const rect = e.target.getBoundingClientRect();
    $("#root").append(el.detach());
    el.css({left: rect.x, top: rect.y, width: rect.width, height: rect.height, position: 'absolute'});
    el.data('originx', rect.x);
    el.data('originy', rect.y);
    el.data('dsx', e.clientX);
    el.data('dsy', e.clientY);
    
    draggedEl = el.get(0) as HTMLElement;
  };

  const handleDrag = (e: any) => {
    console.log('dragging');
    
    if (draggedEl == null) {
      return;
    }
    
    const de = $(draggedEl);
    
    const dx = e.clientX - parseInt(de.data('dsx'), 10);
    const dy = e.clientY - parseInt(de.data('dsy'), 10);
    const newPosX = parseInt(de.data('originx'), 10) + dx;
    const newPosY = parseInt(de.data('originy'), 10) + dy;

    draggedEl.style.left = newPosX + 'px';
    draggedEl.style.top = newPosY + 'px';
  };

  const handleDragStop = (e: any) => {
    console.log('drag stop')
    
    if (draggedEl == null) {
      return;
    }
    
    let dropX = e.clientX;
    let dropY = e.clientY;

    if (draggedEl != null) {
      draggedEl.style.visibility = 'hidden';
      draggedEl.dispatchEvent(new CustomEvent('custom-drop', {detail: {dropX, dropY}} as any));
    }
    draggedEl = null;
  };

  const handleDragOver = (e: Event) => {
    e.preventDefault();
  };
  const handleDrop = (e: Event) => {
    e.preventDefault();
  };

  const loginUser = (username: string, password: string) => {
    return fetch(baseUrl + '/authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: 'username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password)
    }).then((res: Response) => {
      if (res.ok) {
        res.json().then((user: User) => {
          setUser(user);
        });
        return true;
      }
      console.error("Failed to authenticate!");
      return false;
    }).catch((e: Error) => {
      console.error("Failed to authenticate!");
      return false;
    });
  }

  const logoutUser = () => {
    fetch(baseUrl + '/logout', {
      credentials: 'include'
    }).then((res: Response) => {
      if (res.ok) {
        setUser(null);
      }
    }).catch((e: Error) => {
      // ignore
    });
  }

  const checkUserIsLoggedIn = () => {
    fetch(baseUrl + '/isAuthenticated', {
      credentials: 'include'
    }).then((res: Response) => {
      if (res.ok) {
        res.json().then((user: User) => {
          setUser(user);
        });
      }
    }).catch((e: Error) => {
      // ignore
    });
  }

  useEffect(() => {
    checkUserIsLoggedIn();
  }, []);

  return <>
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      <RouterProvider router={router} />
    </UserContext.Provider>
  </>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />
  },
  {
    path: "/pexeso",
    element: <Pexeso />,
    errorElement: <Error />
  },
  {
    path: "/pexeso/:game",
    element: <Pexeso />,
    errorElement: <Error />
  },
  {
    path: "/match",
    element: <Match />,
    errorElement: <Error />
  },
  {
    path: "/match/words",
    element: <MatchWords />,
    errorElement: <Error />
  },
  {
    path: "/match/penguins",
    element: <MatchPenguins />,
    errorElement: <Error />
  },
  {
    path: "/match/animals",
    element: <MatchAnimals />,
    errorElement: <Error />
  },
  {
    path: "/match/shapes",
    element: <MatchShapes />,
    errorElement: <Error />
  },
  {
    path: "/match/chosen",
    element: <MatchChosen />,
    errorElement: <Error />
  },
  /* {
    path: "/maths",
    element: <Maths />,
    errorElement: <Error />
  },
  {
    path: "/maths/cats",
    element: <CatMaths />,
    errorElement: <Error />
  }, */
  {
    path: "/info",
    element: <Info />,
    errorElement: <Error />
  },
  {
    path: "/info/:year/files",
    element: <InfoYearFiles />,
    errorElement: <Error />
  },
  // {
  //   path: "/xmas-ms",
  //   element: <XmasMS />,
  //   errorElement: <Error />
  // },
  // {
  //   path: "/xmas-zs",
  //   element: <XmasZS />,
  //   errorElement: <Error />
  // },
]);

export default App;