import prettyBytes from 'pretty-bytes';
import React from 'react';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { FileInfo } from '../App';
import { styleDefObj } from '../util/icon-styles';
import { icon_trash } from './Icons';

interface FileInfoListProps {
    files: FileInfo[]
    isAuthenticated: () => boolean
    fileAction: (file: FileInfo) => void
    deleteAction: (file: FileInfo) => void
}

function FileInfoList({ files, isAuthenticated, fileAction, deleteAction }: FileInfoListProps) {

    const getFileIcon = (fileType: string) => {
        const ext = fileType as DefaultExtensionType;
        const customDefaultLabelColor = styleDefObj[ext] ? styleDefObj[ext]["labelColor"] ?? "#777" : "#777";
        const libDefaultGlyphColor = defaultStyles[ext] && defaultStyles[ext]["labelColor"];
    
        return <FileIcon
            extension={fileType} 
            color={"#DDDDDD"}
            glyphColor={libDefaultGlyphColor ?? customDefaultLabelColor} {...defaultStyles[ext]}
            labelColor={customDefaultLabelColor}
            {...defaultStyles[ext]}
            {...styleDefObj[ext]}
        />;
    };

    return <>
      <div className="file-list">
        {files.map(function(file, _index) {
          return <div className="file-info" key={file.fileId}>
              <div className="file-icon">
                {getFileIcon(file.fileType)}
              </div>
              <span className="file-link">
                <span className="cursor-pointer hover:underline" onClick={() => fileAction(file)}>
                  {file.fileName}
                </span>
              </span>
              <span className="file-size">
                <span>{prettyBytes(file.fileSize, {locale: 'sk'})}</span>
              </span>
              {isAuthenticated() &&
                <span className="file-action cursor-pointer text-amber-500" onClick={() => deleteAction(file)}>
                  {icon_trash}
                </span>
              }
            </div>;
        })}
        
        {(files == null || files.length == 0) &&
          <>
            <div className="file-list-empty">Žiadne súbory</div>
          </>
        }
      </div>
    </>
}

export default FileInfoList;