import React, { useState } from 'react';
import { useEffect } from "react";
import Header from "../components/Header";
import $ from "jquery";
import party from "party-js";
import { useParams } from 'react-router-dom';

const PexesoGame: any = {};

function Pexeso() {
  const params = useParams();
  const gameId = params.game;

  useEffect(() => {
    document.querySelector("#root")!.classList.add('game-pexeso');
    PexesoGame.init(gameId);

    return () => {
        document.querySelector("#root")!.classList.remove('game-pexeso');
        PexesoGame.dispose();
    };
  }, [gameId]);

  return <>
    <Header active="pexeso" />
	
    <div id="tiles"></div>
    <div id="board-wrapper">
      <div id="board"></div>
    </div>
  </>
}

PexesoGame.init = function(gameId?: string) {
    PexesoGame.tiles = {}; // id: tile
    PexesoGame.tilesOrder = [];
    PexesoGame.flipped = [];
    PexesoGame.waitClick = null;
    PexesoGame.centerTimeout = null;
    PexesoGame.playing = false;
    PexesoGame.initialized = false;
    PexesoGame.resizeObserver = null;
    PexesoGame.disposed = false;
    
    PexesoGame.beforeUnload = function(e: any) {
        if (PexesoGame.playing) {
            e.preventDefault();
            return e.returnValue = " ";
        }
    }

    PexesoGame.handleClick = function(evt: any) {
        if (PexesoGame.waitClick != null) {
            PexesoGame.waitClick();
            PexesoGame.waitClick = null;
            return;
        }
        
        let target: any = $(evt.target);
        if (!target.is(".tile")) {
            target = target.parents(".tile");
        }
        if (target.is(".tile")) {
            PexesoGame.flip(target);
        }
    }

    PexesoGame.handleResize = function() {
        PexesoGame.updateGrid();
        PexesoGame.centerBoard();
    }
    
    window.addEventListener("beforeunload", PexesoGame.beforeUnload, false);
    document.addEventListener("click", PexesoGame.handleClick);
    
    $("body, #board-wrapper").on("dblclick", function(e) {
        if ($(e.target).is($("body, #board-wrapper"))) {
            const boardWrapper = $("#board-wrapper")[0].getBoundingClientRect();
            $("#board").css({width: boardWrapper.width, height: boardWrapper.height});
            PexesoGame.centerBoardInternal();
        }
    });
    
    PexesoGame.resizeObserver = new ResizeObserver(function() {
        PexesoGame.updateGrid();
        PexesoGame.centerBoard();
    });
    PexesoGame.resizeObserver.observe(document.querySelector("#board")!);
    
    window.addEventListener("resize", PexesoGame.handleResize);

    const now = new Date().getTime();
    if (gameId && gameId.length) {
        for (const game of PexesoGame.games) {
            if (game['after'] && now < game.after) {
                continue;
            }
            if (game.id == gameId) {
                PexesoGame.start(game);
                return;
            }
        }
    }

    $("#tiles").show();
    $("#board-wrapper").hide();
    $("#board").empty();

    for (const game of PexesoGame.games) {
        if (game['after'] && now < game.after) {
            continue;
        }
        // const gameDiv = $("<div class='game'><span>" + game.name + "</span></div>");
        const gameLink = $('<a class="link" href="/pexeso/' + game.id + '"><span>' + game.name + '</span></a>');
        $("#tiles").append(gameLink);
    }
}

PexesoGame.dispose = function() {
    PexesoGame.disposed = true;

    $("body").css("background-color", "white");

    if (PexesoGame.centerTimeout != null) {
        window.clearTimeout(PexesoGame.centerTimeout);
    }

    window.removeEventListener("beforeunload", PexesoGame.beforeUnload, false);
    document.removeEventListener("click", PexesoGame.handleClick);
    $("body, #board-wrapper").off("dblclick");
    // PexesoGame.resizeObserver.unobserve(document.querySelector("#board")!);
    window.removeEventListener("resize", PexesoGame.handleResize);
}

PexesoGame.games = [
    {
        id: 'abc',
        name: 'Abeceda',
        base: '/images/pexeso/letters/game1/',
        images: ["a.png", "e.png", "i.png", "j.png", "l.png", "m.png", "o.png", "s.png"],
        baseColor: 'bb7d05'
    },
    {
        id: 'nat',
        name: 'Príroda',
        base: '/images/pexeso/nature/',
        images: ["d.png", "e.png", "h.png", "j.png", "k.png", "l.png", "m.png", "n.png", "o.png", "p.png", "s.png", "t.png", "v.png", "z.png"],
        baseColor: 'c7e572'
    },
    {
        id: 'ani',
        name: 'Zvieratká',
        base: '/images/pexeso/animals/',
        images: ["p01.png", "p02.png", "p03.png", "p04.png", "p05.png", "p06.png", "p07.png", "p08.png", "p09.png", "p10.png", "p11.png"],
        baseColor: '8a2be2'
    },
    {
        id: 'chr',
        after: 1640300400000,
        name: 'Vianoce',
        base: '/images/pexeso/christmas/game1/',
        images: ["01.png", "05.png", "07.png", "08.png", "09.png", "10.png", "11.png", "14.png", "15.png", "18.png", "19.png", "22.png", "24.png"],
        baseColor: '7ECCBE'
    }
];

PexesoGame.start = function(game: any) {
    PexesoGame.flipped = [];
    PexesoGame.waitClick = null;
    PexesoGame.tiles = {};
    PexesoGame.playing = true;
    
    const images = game.images;
    const base = game.base;
    for (let i=0; i<images.length; i++) {
        PexesoGame.tiles[(2*i)] = {id: (2*i), img: base + images[i], pairId: (2*i) + 1};
        PexesoGame.tiles[(2*i) + 1] = {id: (2*i) + 1, img: base + images[i], pairId: (2*i)};
    }
    
    const tilesCount = Object.keys(PexesoGame.tiles).length;
    
    $("#tiles").hide();
    $("#board-wrapper").show();
    $("#board").empty();
    
    for (let i=0; i<tilesCount; i++) {
        const tileEl = $("<div class='tile'><div class='content'><div class='front'></div><div class='back'></div></div></div>");
        $("#board").append(tileEl);
    }
    
    $("body").css("background-color", "#" + game.baseColor + "1f");
    $("#board").css("border", "2px dashed #" + game.baseColor);
    $(".tile .front").css("background-color", "#" + game.baseColor);
    
    PexesoGame.tilesOrder = [...Array(tilesCount).keys()];
    PexesoGame.shuffle();
    
    $("#board .tile").each((index, _tileEl) => {
        const tileEl = $(_tileEl);
        const tile = PexesoGame.tiles[PexesoGame.tilesOrder[index]];
        tile.el = tileEl;
        tile.visible = true;
        tile.flipped = false;

        const img = $("<img />");
        img.attr("src", tile.img);
        img.attr("draggable", 'false');
        img.on("dragstart", function(e) {
            e.preventDefault();
            return false;
        });

        tileEl.data("id", tile.id);
        tileEl.removeClass("flipped faded");
        tileEl.find('.back').empty().append(img);
        tileEl.show();
    });

    PexesoGame.updateGrid();
    PexesoGame.centerBoardInternal();
}

PexesoGame.flip = function(tileEl: JQuery<HTMLElement>) {
    const id = tileEl.data("id");
    const tile = PexesoGame.tiles[id];
    
    if (tile.flipped) {
        return;
    }

    if (tileEl.is(".faded")) {
        return;
    }
    
    tile.flipped = true;
    tileEl.addClass("flipped");

    PexesoGame.flipped.push(PexesoGame.tiles[id]);

    if (PexesoGame.flipped.length == 2) {
        let tile1 = PexesoGame.flipped[0];
        let tile2 = PexesoGame.flipped[1];

        if (tile1.pairId == tile2.id && tile2.pairId == tile1.id) {
            party.sparkles(tile1.el[0], {count: party.variation.range(20, 30), lifetime: 2});
            party.sparkles(tile2.el[0], {count: party.variation.range(20, 30), lifetime: 2});
            PexesoGame.waitClick = () => {PexesoGame.flipMatched(tile1, tile2);};
        } else {
            PexesoGame.waitClick = () => {PexesoGame.flipNotMatched(tile1, tile2);};
        }
        return;
    }
}

PexesoGame.flipMatched = function(tile1: any, tile2: any) {
    PexesoGame.flipped = [];
    tile1.el.addClass("faded");
    tile1.visible = false;
    tile1.flipped = false;
    
    tile2.el.addClass("faded");
    tile2.visible = false;
    tile2.flipped = false;
    
    let allHidden = true;
    for (const id in PexesoGame.tiles) {
        const t = PexesoGame.tiles[id];
        if (t.visible) {
            allHidden = false;
            break;
        }
    }

    if (allHidden) {
        PexesoGame.finished();
        return;
    }
}

PexesoGame.flipNotMatched = function(tile1: any, tile2: any) {
    PexesoGame.flipped = [];
    tile1.flipped = false;
    tile1.el.removeClass("flipped");
    tile2.flipped = false;
    tile2.el.removeClass("flipped");
}

PexesoGame.finished = function() {
    PexesoGame.playing = false;
    $("#board").empty();
    $("#board-wrapper").hide();
    $("body").css("background-color", "white");
    PexesoGame.finishAnimation();
}

PexesoGame.shuffle = function() {
    let currentIndex = PexesoGame.tilesOrder.length, randomIndex;
    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [PexesoGame.tilesOrder[currentIndex], PexesoGame.tilesOrder[randomIndex]] = [PexesoGame.tilesOrder[randomIndex], PexesoGame.tilesOrder[currentIndex]];
    }
}

PexesoGame.centerBoard = function() {
    if (PexesoGame.centerTimeout != null) {
        clearTimeout(PexesoGame.centerTimeout);
    }
    PexesoGame.centerTimeout = setTimeout(function() {
        if (PexesoGame.disposed) {
            return;
        }
        PexesoGame.centerTimeout = null;
        PexesoGame.centerBoardInternal();
    }, 500);
}

PexesoGame.centerBoardInternal = function() {
    const boardWrapper = $("#board-wrapper")[0].getBoundingClientRect();
    const board = $("#board")[0].getBoundingClientRect();

    if (board.width > boardWrapper.width) {
        $("#board").css({width: boardWrapper.width + "px"});
    }
    if (board.height > boardWrapper.height) {
        $("#board").css({height: boardWrapper.height + "px"});
    }

    const posX = (boardWrapper.width / 2) - (board.width / 2);
    const posY = (boardWrapper.height / 2) - (board.height / 2);
    $("#board").css({position: "absolute", left: posX, top: posY});
}

PexesoGame.updateGrid = function() {
    // Compute number of rows and columns, and cell size
    var x = $("#board").width() || 0;
    var y = $("#board").height() || 0;
    var n = Object.keys(PexesoGame.tiles).length;
    var ratio = x / y;
    var ncols_float = Math.sqrt(n * ratio);
    var nrows_float = n / ncols_float;

    // Find best option filling the whole height
    var nrows1 = Math.ceil(nrows_float);
    var ncols1 = Math.ceil(n / nrows1);
    while (nrows1 * ratio < ncols1) {
        nrows1++;
        ncols1 = Math.ceil(n / nrows1);
    }
    var cell_size1 = y / nrows1;

    // Find best option filling the whole width
    var ncols2 = Math.ceil(ncols_float);
    var nrows2 = Math.ceil(n / ncols2);
    while (ncols2 < nrows2 * ratio) {
        ncols2++;
        nrows2 = Math.ceil(n / ncols2);
    }
    var cell_size2 = x / ncols2;

    // Find the best values
    var nrows, ncols, cell_size;
    if (cell_size1 < cell_size2) {
        nrows = nrows2;
        ncols = ncols2;
        cell_size = cell_size2;
    } else {
        nrows = nrows1;
        ncols = ncols1;
        cell_size = cell_size1;
    }
    var size = cell_size - 10;

    $("#board .tile, #board .tile .back img").css({width: size + "px", height: size + "px"});
}

PexesoGame.finishAnimation = function() {
    const source = document.body;
    // party.confetti(document.body, {count: party.variation.range(75, 100), lifetime: 5});

    const populated = party.util.overrideDefaults(
        {
            lifetime: 5,
            count: party.variation.range(75, 100),
            speed: party.variation.range(100, 200),
            size: party.variation.range(0.8, 1.8),
            rotation: () => new party.Vector(0, 0, party.random.randomRange(0, 360)),
            color: () => party.Color.fromHsl(50, 100, party.random.randomRange(55, 85)),
            modules: [
                new party.ModuleBuilder()
                    .drive("rotation")
                    .by((t) => new party.Vector(0, 0, 200).scale(t))
                    .relative()
                    .build(),
                new party.ModuleBuilder()
                    .drive("size")
                    .by(
                        new party.NumericSpline(
                            { time: 0, value: 0 },
                            { time: 0.3, value: 1 },
                            { time: 0.7, value: 1 },
                            { time: 1, value: 0 }
                        )
                    )
                    .through("relativeLifetime")
                    .relative()
                    .build(),
                new party.ModuleBuilder()
                    .drive("opacity")
                    .by(
                        new party.NumericSpline(
                            { time: 0, value: 1 },
                            { time: 0.5, value: 1 },
                            { time: 1, value: 0 }
                        )
                    )
                    .through("relativeLifetime")
                    .build(),
            ],
        },
        {}
    );

    party.scene.current.createEmitter({
        emitterOptions: {
            loops: 1,
            duration: 5,
            useGravity: false,
            modules: populated.modules
        },
        emissionOptions: {
            rate: 0,
            bursts: [{ time: 0, count: populated.count }, { time: 1, count: populated.count }, { time: 2, count: populated.count }, { time: 3, count: populated.count }, { time: 4, count: populated.count }],
            sourceSampler: party.sources.dynamicSource(source),
            angle: party.variation.range(0, 360),
            initialLifetime: populated.lifetime,
            initialSpeed: populated.speed,
            initialSize: populated.size,
            initialRotation: populated.rotation,
            initialColor: populated.color
        },
        rendererOptions: {
            shapeFactory: ["star"],
            applyLighting: undefined,
        },
    });
}

export default Pexeso;