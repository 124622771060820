import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

function Info() {
  const years = [3, 4, 5, 6, 7, 8];

  return <>
    <Header active="info" />

    <div id="tiles">
      {years.map((year) =>
        <Link key={year.toString()} id={"info-" + year} className="link" to={"/info/" + year + "/files"}>
          <span>{year}. ročník</span>
        </Link>
      )}
    </div>
  </>
}

export default Info;