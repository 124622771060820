import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/Header';
import { FileInfo, FolderType, UserContext } from '../App';
import { icon_copy_success, icon_download_all, icon_eye } from '../components/Icons';
import { useParams } from 'react-router-dom';
import FileInfoList from '../components/FileInfoList';
import DropZone from '../components/DropZone';
import { config } from '../util/constants';

const baseUrl = config.API_URL;

function InfoYearFiles() {
  const userCtx = useContext(UserContext);
  const params = useParams();
  const year = params.year;
  const folder = "info-" + year;
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileInfo | null>(null);
  const [folderType, setFolderType] = useState<FolderType>(FolderType.public);
  const [showUploadAssignment, setShowUploadAssignment] = useState(false);
  const [showUploadAssignmentConfirmation, setShowUploadAssignmentConfirmation] = useState(false);
  const [uploadFileList, setUploadFileList] = useState<File[] | null>(null);
  const [uploadPrivateFileList, setUploadPrivateFileList] = useState<File[] | null>(null);

  useEffect(() => {
    setFolderType(FolderType.public);
    loadFiles();
  }, [year]);

  useEffect(() => {
    loadFiles();
  }, [folderType]);

  useEffect(() => {
    startUpload();
  }, [uploadFileList]);

  useEffect(() => {
    startPrivateUpload();
  }, [uploadPrivateFileList]);

  const loadFiles = () => {
    fetch(baseUrl + '/list/' + folderType + '/' + encodeURIComponent(folder), {
      credentials: 'include'
    }).then((res: Response) => {
      if (res.ok) {
        res.json().then((jsonFiles) => {
          setFiles(jsonFiles);
        });
      } else {
        setFiles([]);
      }
    }).catch((e: Error) => {
      console.error("Failed to load files for folder [" + folder + "]!", e);
      setFiles([]);
    });
  };

  const downloadFile = (file: FileInfo) => {
    window.location.href = `${baseUrl}/download/${folderType}/${folder}/${file.fileId}`;
  };
  
  const downloadFolder = () => {
    window.location.href = `${baseUrl}/downloadAll/${folderType}/${folder}`;
  }

  const confirmDeleteFile = (file: FileInfo) => {
    setFileToDelete(file);
    setShowDeleteConfirm(true);
  };

  const deleteFile = () => {
    fetch(baseUrl + '/delete/' + folderType + '/' + encodeURIComponent(folder) + "/" + encodeURIComponent(fileToDelete!.fileId), {
      credentials: 'include'
    }).then((_res: Response) => {
      // nothing to do, just reload
    }).catch((e: Error) => {
      console.error("Failed to delete file [" + fileToDelete!.fileName + "] from folder [" + folder + "]!", e);
    }).finally(() => {
      loadFiles();
    });

    setFileToDelete(null);
    setShowDeleteConfirm(false);
  };

  const startUpload = () => {
    if (uploadFileList == null || uploadFileList.length == 0) {
      return;
    }
    
    const fileToUpload = uploadFileList[0];
    
    if (!fileToUpload) {
      setUploadFileList(null);
      return;
    }

    const formData  = new FormData();
    formData.append("folderType", folderType);
    formData.append("folder", folder);
    formData.append("file", fileToUpload);
    
    doUpload(formData, fileToUpload.name).then(() => {
      setUploadFileList(uploadFileList.slice(1));
      loadFiles();
    });
  };

  const startPrivateUpload = () => {
    if (uploadPrivateFileList == null || uploadPrivateFileList.length == 0) {
      return;
    }
    
    const fileToUpload = uploadPrivateFileList[0];
    
    if (!fileToUpload) {
      setUploadPrivateFileList(null);
      return;
    }

    const formData  = new FormData();
    formData.append("folderType", FolderType.private);
    formData.append("folder", folder);
    formData.append("file", fileToUpload);
    
    doUpload(formData, fileToUpload.name).then(() => {
      const newList = uploadPrivateFileList.slice(1);
      setUploadPrivateFileList(newList);
      if (newList.length == 0) {
        setShowUploadAssignment(false);
        setShowUploadAssignmentConfirmation(true);
        setTimeout(() => {
          setShowUploadAssignmentConfirmation(false);
        }, 5000);
      }
      loadFiles();
    });
  };

  const doUpload = (formData: FormData, fileName: string) => {
    return new Promise<void>((resolve, _reject) => {
      fetch(baseUrl + '/upload', {
        credentials: 'include',
        method: 'POST',
        body: formData
      }).then((res: Response) => {
        if (!res.ok) {
          console.error("Failed to upload file [" + fileName + "] to folder [" + folder + "]!");
        }
      }).catch((e: Error) => {
        console.error("Failed to upload file [" + fileName + "] to folder [" + folder + "]!", e);
      }).finally(() => {
        resolve();
      });
    });
  };

  const isAuthenticated = () => {
    return userCtx.user != null;
  };

  return <>
    <Header active="info" />
    
    {isAuthenticated() && <>
      <div className="tabs-wrapper">
        <ul className="tabs-content">
            <li className="mr-2">
                <span onClick={() => setFolderType(FolderType.public)} className={"tab-button " + (folderType == FolderType.public ? "tab-button-active " : " ")}>
                    {icon_eye} <span className="ml-2">{year}. ročník</span>
                </span>
            </li>
            <li className="mr-2">
                <span onClick={() => setFolderType(FolderType.private)} className={"tab-button " + (folderType == FolderType.private ? "tab-button-active " : " ")}>
                    {icon_copy_success} <span className="ml-2">Odovzdané</span>
                </span>
            </li>
        </ul>
      </div>

      <div className='flex mr-3'>
        <span className={'icon-link ml-auto mr-4 mt-3 mb-1 items-center flex items-center ' + ((files == null || files.length == 0) ? "invisible " : " ")} onClick={downloadFolder}>
          {icon_download_all}
          <span className='ml-2'>Stiahnuť</span>
        </span>
      </div>

      <DropZone setUploadFileList={setUploadFileList}>
        <FileInfoList isAuthenticated={isAuthenticated} files={files} fileAction={downloadFile} deleteAction={confirmDeleteFile} />
      </DropZone>

      {uploadFileList != null && uploadFileList.length > 0 && <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-2xl font-semibold">
                  Nahrávanie súborov
                </h3>
              </div>
              
              <div className="relative p-6 flex-auto">
                {uploadFileList.map(function(file, _index) {
                  return <div key={file.name} className="">
                    {file.name}
                  </div>;
                })}
              </div>
              
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>}

      {showDeleteConfirm && fileToDelete && <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-2xl font-semibold">
                  Potvrdenie
                </h3>
                <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowDeleteConfirm(false)}>
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              
              <div className="relative p-6 flex-auto">
                Naozaj chcete vymazať súbor {fileToDelete.fileName}?
              </div>
              
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button className="btn-secondary" type="button" onClick={() => setShowDeleteConfirm(false)}>
                  Zrušiť
                </button>
                <button className="btn-primary ml-2" type="button" onClick={deleteFile}>
                  Vymazať
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>}
    </>}
    
    {!isAuthenticated() && <>
      <div className="flex">
        <div className="heading self-center">
          <h2>{year}. ročník</h2>
        </div>
        <div className="self-center">
          <button className='btn-primary' onClick={() => setShowUploadAssignment(true)}>Odovzdať</button>
        </div>
      </div>

      <FileInfoList isAuthenticated={isAuthenticated} files={files} fileAction={downloadFile} deleteAction={() => {}} />

      {showUploadAssignment && <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-2xl font-semibold">
                  Odovzdať zadanie
                </h3>
                <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowUploadAssignment(false)}>
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              
              <div className="relative p-6 flex-auto">
                {(uploadPrivateFileList == null || uploadPrivateFileList.length == 0) &&
                  <DropZone setUploadFileList={setUploadPrivateFileList}>
                    <div className="assignment-drop-zone">
                        Ťahaj a pusti súbory sem
                    </div>
                  </DropZone>
                }
                {(uploadPrivateFileList != null && uploadPrivateFileList.length > 0) &&
                  <>
                    {uploadPrivateFileList.map(function(file, _index) {
                      return <div key={file.name} className="">
                        {file.name}
                      </div>;
                    })}
                  </>
                }
              </div>
              
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button className="btn-secondary" type="button" onClick={() => setShowUploadAssignment(false)}>
                  Zavrieť
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>}

      {showUploadAssignmentConfirmation && <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              
              <div className="relative p-6 flex-auto">
                Súbory boli úspešne odovzdané!
              </div>
              
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>}

    </>}
  </>
}

export default InfoYearFiles;