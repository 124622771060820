import React, { useEffect } from 'react';
import Header from '../components/Header';
import $ from "jquery";
import party from "party-js";

const MatchShapesGame: any = {};

function MatchShapes() {
  useEffect(() => {
    document.querySelector("#root")!.classList.add('game-match-shapes');
    MatchShapesGame.init();

    return () => {
        document.querySelector("#root")!.classList.remove('game-match-shapes');
        MatchShapesGame.dispose();
    };
  }, []);

  return <>
    <Header active="match" />
    
    <div id="board-wrapper">
        <div id="board">
            <div id="top">
                <div className="category drop" data-id="0"><div className="title">Symetrické</div><div className="cont"></div></div>
                <div className="category drop" data-id="1"><div className="title">Nesymetrické</div><div className="cont"></div></div>
                <div className="shapes"></div>
            </div>
            <div id="bottom">
                <span className="points-label">Počet bodov:</span><span id="points"></span>
            </div>
        </div>
    </div>
  </>
}

MatchShapesGame.init = function() {
  MatchShapesGame.game = null;
  MatchShapesGame.playing = false;
  MatchShapesGame.points = 0;
  MatchShapesGame.lastDrop = true;
  MatchShapesGame.shapeCategory = {"1.png": 0, "2.png": 0, "3.png": 0, "4.png": 0, "5.png": 0, "6.png": 0, "7.png": 1, "8.png": 1,
      "9.png": 0, "10.png": 1, "11.png": 1, "12.png": 0, "13.png": 0, "14.png": 0, "15.png": 1, "16.png": 1, "17.png": 1,
      "18.png": 1, "19.png": 1, "20.png": 0, "21.png": 0, "22.png": 1, "23.png": 1, "24.png": 1};
  MatchShapesGame.shapesOrder = [];

  MatchShapesGame.beforeUnload = function(e: any) {
    if (MatchShapesGame.playing) {
        e.preventDefault();
        return e.returnValue = " ";
    }
  }

  MatchShapesGame.handleContextMenu = function(e: any) {
    e.preventDefault();
    return false;
  }

  window.addEventListener("beforeunload", MatchShapesGame.beforeUnload, false);
  document.addEventListener("contextmenu", MatchShapesGame.handleContextMenu);

  $("body, #board-wrapper").on("dblclick", function(e) {
    if ($(e.target).is($("body, #board-wrapper"))) {
        const boardWrapper = $("#board-wrapper")[0].getBoundingClientRect();
        $("#board").css({width: boardWrapper.width, height: boardWrapper.height});
    }
  });

  MatchShapesGame.start(MatchShapesGame.games[0]);
}

MatchShapesGame.dispose = function() {
  $("body").css("background-color", "white");

  $("body, #board-wrapper").off("dblclick");
  window.removeEventListener("beforeunload", MatchShapesGame.beforeUnload, false);
  document.removeEventListener("contextmenu", MatchShapesGame.handleContextMenu);
}

MatchShapesGame.games = [
  {
    base: '/images/match_shapes/',
    images: ["1.png", "2.png", "3.png", "4.png", "5.png", "6.png", "7.png", "8.png",
            "9.png", "10.png", "11.png", "12.png", "13.png", "14.png", "15.png", "16.png",
            "17.png", "18.png", "19.png", "20.png", "21.png", "22.png", "23.png", "24.png"],
        categories: ["Symetrické", "Nesymetrické"],
    baseColor: 'bb7d05'
  }
];

MatchShapesGame.start = function(game: any) {
  MatchShapesGame.game = game;
  MatchShapesGame.playing = true;
  MatchShapesGame.points = 0;
  MatchShapesGame.lastDrop = true;

  $("#board-wrapper").css("display", "flex");
  $("#game-match").css("background-color", "#" + game.baseColor + "1f");
  $("#points").text("0");

  MatchShapesGame.shapesOrder = [...Array(game.images.length).keys()];
  MatchShapesGame.shuffle();

  for (let i=0; i<MatchShapesGame.shapesOrder.length; i++) {
      const shapeImgName = game.images[MatchShapesGame.shapesOrder[i]];
      const shapeImg = game.base + shapeImgName;

      const shapeEl = $("<img class='shape draggable ghost-img' draggable='false' ondragstart='return false;' src='" + shapeImg + "' />");
      shapeEl.data("category", MatchShapesGame.shapeCategory[shapeImgName]);
      MatchShapesGame.draggable(shapeEl, shapeImg);

      $("#board .shapes").append(shapeEl);
  }
  
  $("#board .shapes .shape:last").show();

  $("body").css("background-color", "#ffffff");
  $("#board").css("border-right", "2px dashed #000000");
}

MatchShapesGame.shuffle = function() {
  let currentIndex = MatchShapesGame.shapesOrder.length, randomIndex;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [MatchShapesGame.shapesOrder[currentIndex], MatchShapesGame.shapesOrder[randomIndex]] = [MatchShapesGame.shapesOrder[randomIndex], MatchShapesGame.shapesOrder[currentIndex]];
  }
}

MatchShapesGame.draggable = function(shape: JQuery<HTMLElement>) {
    shape[0].addEventListener('custom-drop', (e: any) => {
        let dropX = e.detail.dropX;
        let dropY = e.detail.dropY;

        let dropTarget = $(document.elementFromPoint(dropX, dropY)!);
          let dropCategory;
          if (dropTarget.is(".category.drop")) {
              dropCategory = dropTarget;
          } else if (dropTarget.parents(".category.drop").length) {
              dropCategory = dropTarget.parents(".category.drop");
          }

          if (dropCategory) {
            if (dropCategory.data("id") === shape.data("category")) {
                dropCategory.find("div.cont").append(shape.detach());
                shape.css({"visibility": "visible", "position": 'relative', left: 'auto', top: 'auto', width: 'auto', height: 'auto'});
                shape.removeClass('draggable');
                party.sparkles(shape.get(0)!, {count: party.variation.range(20, 30), lifetime: 2});
  
                if (MatchShapesGame.lastDrop) {
                  MatchShapesGame.score();
                }
  
                MatchShapesGame.lastDrop = true;
                $("#board .shapes .shape:last").show();
                return;
            } else {
                MatchShapesGame.lastDrop = false;
            }
          }
          shape.css({"visibility": "visible", left: shape.data('originx'), top: shape.data('originy')});
    });
}

MatchShapesGame.score = function() {
  MatchShapesGame.points++;
  $("#points").text(MatchShapesGame.points);
}

MatchShapesGame.finishAnimation = function() {
  const source = document.body;
  // party.confetti(document.body, {count: party.variation.range(75, 100), lifetime: 5});

  const populated = party.util.overrideDefaults(
      {
          lifetime: 5,
          count: party.variation.range(75, 100),
          speed: party.variation.range(100, 200),
          size: party.variation.range(0.8, 1.8),
          rotation: () => new party.Vector(0, 0, party.random.randomRange(0, 360)),
          color: () => party.Color.fromHsl(50, 100, party.random.randomRange(55, 85)),
          modules: [
              new party.ModuleBuilder()
                  .drive("rotation")
                  .by((t) => new party.Vector(0, 0, 200).scale(t))
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("size")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 0 },
                          { time: 0.3, value: 1 },
                          { time: 0.7, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("opacity")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 1 },
                          { time: 0.5, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .build(),
          ],
      },
      {}
  );

  party.scene.current.createEmitter({
      emitterOptions: {
          loops: 1,
          duration: 5,
          useGravity: false,
          modules: populated.modules
      },
      emissionOptions: {
          rate: 0,
          bursts: [{ time: 0, count: populated.count }, { time: 1, count: populated.count }, { time: 2, count: populated.count }, { time: 3, count: populated.count }, { time: 4, count: populated.count }],
          sourceSampler: party.sources.dynamicSource(source),
          angle: party.variation.range(0, 360),
          initialLifetime: populated.lifetime,
          initialSpeed: populated.speed,
          initialSize: populated.size,
          initialRotation: populated.rotation,
          initialColor: populated.color
      },
      rendererOptions: {
          shapeFactory: ["star"],
          applyLighting: undefined,
      },
  });
}

export default MatchShapes;