import React from 'react';
import { useEffect } from 'react';

function Error() {
  return <>
    <header>
      <div id="logo" onClick={() => {window.location.href = '/'}}>Šikulík</div>
    </header>

    <div className="error-content">
      Nastala chyba!
    </div>
  </>
}

export default Error;