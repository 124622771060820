import React, { useEffect } from 'react';
import Header from '../components/Header';
import $ from "jquery";
import party from "party-js";

const MatchAnimalsGame: any = {};

function MatchAnimals() {
  useEffect(() => {
    document.querySelector("#root")!.classList.add('game-match-animals');
    MatchAnimalsGame.init();

    return () => {
        document.querySelector("#root")!.classList.remove('game-match-animals');
        MatchAnimalsGame.dispose();
    };
  }, []);

  return <>
    <Header active="match" />
    
    <div id="board-wrapper">
      <div id="board">
        <div className="category drop" data-id="0"><div className="title">Bylinožravé</div><div className="cont"></div></div>
        <div className="category drop" data-id="1"><div className="title">Mäsožravé</div><div className="cont"></div></div>
        <div className="category drop" data-id="2"><div className="title">Všežravé</div><div className="cont"></div></div>
        <div className="animals"></div>
      </div>
    </div>
  </>
}

MatchAnimalsGame.init = function() {
  MatchAnimalsGame.game = null;
  MatchAnimalsGame.playing = false;
  MatchAnimalsGame.animalCategory = {"had.png": 1, "jelen.png": 0, "jez.png": 1, "kohut.png": 2, "kon.png": 0, "krokodil.png": 1, "medved.png": 2, "orol.png": 1,
      "ovca.png": 0, "pavuk.png": 1, "prasa.png": 2, "puma.png": 1, "slon.png": 0, "zaba.png": 1, "zajac.png": 0};
  MatchAnimalsGame.animalsOrder = [];

  MatchAnimalsGame.beforeUnload = function(e: any) {
    if (MatchAnimalsGame.playing) {
        e.preventDefault();
        return e.returnValue = " ";
    }
  }

  MatchAnimalsGame.handleContextMenu = function(e: any) {
    e.preventDefault();
    return false;
  }

  window.addEventListener("beforeunload", MatchAnimalsGame.beforeUnload, false);
  document.addEventListener("contextmenu", MatchAnimalsGame.handleContextMenu);

  MatchAnimalsGame.start(MatchAnimalsGame.games[0]);
}

MatchAnimalsGame.dispose = function() {
  $("body").css("background-color", "white");

  document.onmouseup = null;
  document.ontouchend = null;
  document.onmousemove = null;
  document.ontouchmove = null;

  window.removeEventListener("beforeunload", MatchAnimalsGame.beforeUnload, false);
  document.removeEventListener("contextmenu", MatchAnimalsGame.handleContextMenu);
}

MatchAnimalsGame.games = [
  {
    base: '/images/match_animals/',
    images: ["had.png", "jelen.png", "jez.png", "kohut.png", "kon.png", "krokodil.png", "medved.png", "orol.png",
            "ovca.png", "pavuk.png", "prasa.png", "puma.png", "slon.png", "zaba.png", "zajac.png"],
        categories: ["Bylinožravé", "Mäsožravé", "Všežravé"],
    baseColor: 'bb7d05'
  }
];

MatchAnimalsGame.start = function(game: any) {
  MatchAnimalsGame.game = game;
  MatchAnimalsGame.playing = true;

  $("#board-wrapper").css("display", "flex");
  $("#game-match-animals").css("background-color", "#" + game.baseColor + "1f");

  MatchAnimalsGame.animalsOrder = [...Array(game.images.length).keys()];
  MatchAnimalsGame.shuffle();

  for (let i=0; i<MatchAnimalsGame.animalsOrder.length; i++) {
      const animalImgName = game.images[MatchAnimalsGame.animalsOrder[i]];
      const animalImg = game.base + animalImgName;

      const animalEl = $("<img class='animal draggable ghost-img' draggable='false' ondragstart='return false;' src='" + animalImg + "' />");
      animalEl.data("category", MatchAnimalsGame.animalCategory[animalImgName]);
      MatchAnimalsGame.draggable(animalEl, animalImg);

      $("#board .animals").append(animalEl);
  }

  $("#board .animals .animal:last").show();
  
  $("body").css("background-color", "#ffffff");
  $("#board").css("border-right", "2px dashed #000000");
}

MatchAnimalsGame.shuffle = function() {
  let currentIndex = MatchAnimalsGame.animalsOrder.length, randomIndex;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [MatchAnimalsGame.animalsOrder[currentIndex], MatchAnimalsGame.animalsOrder[randomIndex]] = [MatchAnimalsGame.animalsOrder[randomIndex], MatchAnimalsGame.animalsOrder[currentIndex]];
  }
}

MatchAnimalsGame.draggable = function(shape: JQuery<HTMLElement>) {
  shape[0].addEventListener('custom-drop', (e: any) => {
    let dropX = e.detail.dropX;
    let dropY = e.detail.dropY;

    let dropTarget = $(document.elementFromPoint(dropX, dropY)!);
    let dropCategory;
    if (dropTarget.is(".category.drop")) {
        dropCategory = dropTarget;
    } else if (dropTarget.parents(".category.drop").length) {
        dropCategory = dropTarget.parents(".category.drop");
    }

    if (dropCategory) {
      if (dropCategory.data("id") === shape.data("category")) {
          dropCategory.find("div.cont").append(shape.detach());
          shape.css({"visibility": "visible", "position": 'relative', left: 'auto', top: 'auto', width: 'auto', height: 'auto'});
          shape.removeClass('draggable');
          party.sparkles(shape.get(0)!, {count: party.variation.range(20, 30), lifetime: 2});
          $("#board .animals .animal:last").show();
          return;
      }
    } 

    shape.css({"visibility": "visible", left: shape.data('originx'), top: shape.data('originy')});
  });
}

MatchAnimalsGame.finishAnimation = function() {
  const source = document.body;
  // party.confetti(document.body, {count: party.variation.range(75, 100), lifetime: 5});

  const populated = party.util.overrideDefaults(
      {
          lifetime: 5,
          count: party.variation.range(75, 100),
          speed: party.variation.range(100, 200),
          size: party.variation.range(0.8, 1.8),
          rotation: () => new party.Vector(0, 0, party.random.randomRange(0, 360)),
          color: () => party.Color.fromHsl(50, 100, party.random.randomRange(55, 85)),
          modules: [
              new party.ModuleBuilder()
                  .drive("rotation")
                  .by((t) => new party.Vector(0, 0, 200).scale(t))
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("size")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 0 },
                          { time: 0.3, value: 1 },
                          { time: 0.7, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("opacity")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 1 },
                          { time: 0.5, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .build(),
          ],
      },
      {}
  );

  party.scene.current.createEmitter({
      emitterOptions: {
          loops: 1,
          duration: 5,
          useGravity: false,
          modules: populated.modules
      },
      emissionOptions: {
          rate: 0,
          bursts: [{ time: 0, count: populated.count }, { time: 1, count: populated.count }, { time: 2, count: populated.count }, { time: 3, count: populated.count }, { time: 4, count: populated.count }],
          sourceSampler: party.sources.dynamicSource(source),
          angle: party.variation.range(0, 360),
          initialLifetime: populated.lifetime,
          initialSpeed: populated.speed,
          initialSize: populated.size,
          initialRotation: populated.rotation,
          initialColor: populated.color
      },
      rendererOptions: {
          shapeFactory: ["star"],
          applyLighting: undefined,
      },
  });
}

export default MatchAnimals;