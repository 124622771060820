import React, { useEffect, useRef, useState } from 'react';

interface DropZoneProps {
    children?: React.ReactNode
    classes?: string
    setUploadFileList: (list: File[]) => void
}

function DropZone({ children, classes, setUploadFileList }: DropZoneProps) {
    const [dragging, setDragging] = useState<boolean>(false);
    const dropRef = useRef<HTMLDivElement>(null);
    const dragCounter = useRef<number>(0);

    useEffect(() => {
        dragCounter.current = 0;
        const div = dropRef.current;
        if (div) {
            div.addEventListener('dragenter', handleDragIn)
            div.addEventListener('dragleave', handleDragOut)
            div.addEventListener('dragover', handleDrag)
            div.addEventListener('drop', handleDrop)
        }
        return () => {
            const div = dropRef.current;
            if (div) {
                div.removeEventListener('dragenter', handleDragIn)
                div.removeEventListener('dragleave', handleDragOut)
                div.removeEventListener('dragover', handleDrag)
                div.removeEventListener('drop', handleDrop)
            }
        };
    }, []);

    const handleDrag = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current = dragCounter.current + 1;
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    };
    const handleDragOut = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current = dragCounter.current - 1;
        if (dragCounter.current > 0) return;
        setDragging(false);
    };
    const handleDrop = (e: DragEvent) => {    
        e.preventDefault();
        e.stopPropagation();
        
        dragCounter.current = 0;
        setDragging(false);

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setUploadFileList(Array.from(e.dataTransfer.files));
        }
    };

    return <>
      <div ref={dropRef} className={"flex grow flex-col overflow-y-auto" + (dragging ? "bg-amber-100 " : " ") + "" + (classes ? classes + " " : " ")}>
        {children}
      </div>
    </>
}

export default DropZone;