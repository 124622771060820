import React, { useEffect } from 'react';
import Header from '../components/Header';
import $ from "jquery";
import party from "party-js";

const MatchGame: any = {};

function MatchWords() {
  useEffect(() => {
    document.querySelector("#root")!.classList.add('game-match');
    MatchGame.init();

    return () => {
        document.querySelector("#root")!.classList.remove('game-match');
        MatchGame.dispose();
    };
  }, []);

  return <>
    <Header active="match" />
    
    <div id="board-wrapper">
        <div id="board"></div>
    </div>
  </>
}

MatchGame.init = function() {
  MatchGame.game = null;
  MatchGame.playing = false;
  MatchGame.wordsOrder = [];

  MatchGame.beforeUnload = function(e: any) {
    if (MatchGame.playing) {
        e.preventDefault();
        return e.returnValue = " ";
    }
  }

  MatchGame.handleContextMenu = function(e: any) {
    e.preventDefault();
    return false;
  }

  MatchGame.handleResize = function() {
    MatchGame.updateGrid();
  }

  window.addEventListener("beforeunload", MatchGame.beforeUnload, false);
  document.addEventListener("contextmenu", MatchGame.handleContextMenu);

  $("body, #board-wrapper").on("dblclick", function(e) {
    if ($(e.target).is($("body, #board-wrapper"))) {
        const boardWrapper = $("#board-wrapper")[0].getBoundingClientRect();
        $("#board").css({width: boardWrapper.width, height: boardWrapper.height});
    }
  });

  window.addEventListener("resize", MatchGame.handleResize);

  MatchGame.start(MatchGame.games[0]);
}

MatchGame.dispose = function() {
  $("body").css("background-color", "white");

  $("body, #board-wrapper").off("dblclick");
  window.removeEventListener("beforeunload", MatchGame.beforeUnload, false);
  document.removeEventListener("contextmenu", MatchGame.handleContextMenu);
  window.removeEventListener("resize", MatchGame.handleResize);
}

MatchGame.games = [
  {
     name: 'Slová',
    base: '/images/match/words/',
    images: ["viila.png", "mavaju.png", "misa.png", "mama.png", "lame.png", "lev.png", "vila.png", "les.png", "osa.png", "jama.png", "smejusa.png", "maslo.png", "vola.png", "laso.png", "leje.png", "salama.png"],
     words: ["víla", "mávajú", "misa", "Mama", "láme", "lev", "vila", "les", "osa", "jama", "smejú sa", "maslo", "volá", "laso", "leje", "saláma"],
    baseColor: 'bb7d05'
  }
];

MatchGame.start = function(game: any) {
  MatchGame.game = game;
  MatchGame.playing = true;

  $("#board-wrapper").css("display", "flex");
  $("#board").html("<div id='words-wrapper' class='tile'><div id='words'></div></div>");
  $("#words").css("background-color", "#" + game.baseColor + "1f");

  MatchGame.wordsOrder = [...Array(game.words.length).keys()];
  MatchGame.shuffle();

  for (let i=0; i<game.images.length; i++) {
      const gameImg = game.images[i];

      const tileEl = $("<div class='tile'></div>");
      tileEl.data("id", i);
      
      const img = $("<img />");
      img.attr("src", game.base + gameImg);
      img.attr("draggable", "false");
      img.on("dragstart", function(e) {
          e.preventDefault();
          return false;
      });
      tileEl.append(img);
      
      const drop = $("<div class='drop'></div>");
      drop.data("id", i);
      tileEl.append(drop);
      
      const wordId = MatchGame.wordsOrder[i];
      const wordEl = $("<div class='word draggable ghost-text'>" + game.words[wordId] + "</div>");
      wordEl.data("id", wordId);
      MatchGame.draggable(wordEl);

      $("#board").prepend(tileEl);
      $("#words").append(wordEl);
  }

  $("#board #words .word:first").show();
  
  $("body").css("background-color", "#ffffff");
  $("#board").css("border-right", "2px dashed #000000");
  
  MatchGame.updateGrid();
}

MatchGame.shuffle = function() {
  let currentIndex = MatchGame.wordsOrder.length, randomIndex;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [MatchGame.wordsOrder[currentIndex], MatchGame.wordsOrder[randomIndex]] = [MatchGame.wordsOrder[randomIndex], MatchGame.wordsOrder[currentIndex]];
  }
}

MatchGame.draggable = function(word: JQuery<HTMLElement>) {
    word[0].addEventListener('custom-drop', (e: any) => {
        let dropX = e.detail.dropX;
        let dropY = e.detail.dropY;

        let dropTarget = $(document.elementFromPoint(dropX, dropY)!);
        let dropTile;
        if (dropTarget.is("div.tile")) {
            dropTile = dropTarget;
        } else if (dropTarget.parents("div.tile").length) {
            dropTile = dropTarget.parents("div.tile");
        }

        if (dropTile) {
            if (dropTile.data("id") === word.data("id")) {
                dropTile.find("div.drop").append($("<span>" + word.text() + "</span>"));
                word.remove();
                party.sparkles(dropTile.get(0)! as HTMLElement, {count: party.variation.range(20, 30), lifetime: 2});
    
                if ($("#words").is(":empty")) {
                    $("#words").append("<span class='check-mark'>&check;</span>");
                }
                $("#board #words .word:first").show();
                return;
            } else {
                word.css("visibility", "visible");
            }
        }
        word.css({"visibility": "visible", left: word.data('originx'), top: word.data('originy')});
    });
}

MatchGame.updateGrid = function() {
  // Compute number of rows and columns, and cell size
  var x = $("#board").width();
  var y = $("#board").height();
  var n = MatchGame.game.images.length + 1; // +1 for words tile
  var ratio = x! / y!;
  var ncols_float = Math.sqrt(n * ratio);
  var nrows_float = n / ncols_float;

  // Find best option filling the whole height
  var nrows1 = Math.ceil(nrows_float);
  var ncols1 = Math.ceil(n / nrows1);
  while (nrows1 * ratio < ncols1) {
      nrows1++;
      ncols1 = Math.ceil(n / nrows1);
  }
  var cell_size1 = y! / nrows1;

  // Find best option filling the whole width
  var ncols2 = Math.ceil(ncols_float);
  var nrows2 = Math.ceil(n / ncols2);
  while (ncols2 < nrows2 * ratio) {
      ncols2++;
      nrows2 = Math.ceil(n / ncols2);
  }
  var cell_size2 = x! / ncols2;

  // Find the best values
  var nrows, ncols, cell_size;
  if (cell_size1 < cell_size2) {
      nrows = nrows2;
      ncols = ncols2;
      cell_size = cell_size2;
  } else {
      nrows = nrows1;
      ncols = ncols1;
      cell_size = cell_size1;
  }
  var size = cell_size - 10;

  $("#board .tile").css({width: size + "px", height: size + "px"});
}

MatchGame.finishAnimation = function() {
  const source = document.body;
  // party.confetti(document.body, {count: party.variation.range(75, 100), lifetime: 5});

  const populated = party.util.overrideDefaults(
      {
          lifetime: 5,
          count: party.variation.range(75, 100),
          speed: party.variation.range(100, 200),
          size: party.variation.range(0.8, 1.8),
          rotation: () => new party.Vector(0, 0, party.random.randomRange(0, 360)),
          color: () => party.Color.fromHsl(50, 100, party.random.randomRange(55, 85)),
          modules: [
              new party.ModuleBuilder()
                  .drive("rotation")
                  .by((t) => new party.Vector(0, 0, 200).scale(t))
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("size")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 0 },
                          { time: 0.3, value: 1 },
                          { time: 0.7, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("opacity")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 1 },
                          { time: 0.5, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .build(),
          ],
      },
      {}
  );

  party.scene.current.createEmitter({
      emitterOptions: {
          loops: 1,
          duration: 5,
          useGravity: false,
          modules: populated.modules
      },
      emissionOptions: {
          rate: 0,
          bursts: [{ time: 0, count: populated.count }, { time: 1, count: populated.count }, { time: 2, count: populated.count }, { time: 3, count: populated.count }, { time: 4, count: populated.count }],
          sourceSampler: party.sources.dynamicSource(source),
          angle: party.variation.range(0, 360),
          initialLifetime: populated.lifetime,
          initialSpeed: populated.speed,
          initialSize: populated.size,
          initialRotation: populated.rotation,
          initialColor: populated.color
      },
      rendererOptions: {
          shapeFactory: ["star"],
          applyLighting: undefined,
      },
  });
}

export default MatchWords;