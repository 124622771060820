import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// import {polyfill} from "mobile-drag-drop";

// polyfill();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('service-worker.js');
}