import React, { useEffect } from 'react';
import Header from '../components/Header';
import $ from "jquery";
import party from "party-js";

const MatchGame: any = {};

function MatchPenguins() {
  useEffect(() => {
    document.querySelector("#root")!.classList.add('game-penguins');
    MatchGame.init();

    return () => {
        document.querySelector("#root")!.classList.remove('game-penguins');
        MatchGame.dispose();
    };
  }, []);

  return <>
    <Header active="match" />
    
    <div id="board-wrapper">
        <div id="board">
            <div id='grid'>
            </div>
        </div>
        <div id="penguins"></div>
    </div>
  </>
}

MatchGame.init = function() {
  MatchGame.game = null;
  MatchGame.playing = false;
  MatchGame.wordsOrder = [];

  MatchGame.beforeUnload = function(e: any) {
    // if (MatchGame.playing) {
    //     e.preventDefault();
    //     return e.returnValue = " ";
    // }
  }

  MatchGame.handleContextMenu = function(e: any) {
    e.preventDefault();
    return false;
  }

  window.addEventListener("beforeunload", MatchGame.beforeUnload, false);
  document.addEventListener("contextmenu", MatchGame.handleContextMenu);
  // document.addEventListener("drop", MatchGame.dropHandler);
  // document.addEventListener("pointerup", MatchGame.dropHandler);

  MatchGame.start(MatchGame.games[0]);
}

MatchGame.dispose = function() {
  $("body").css("background-color", "white");

  window.removeEventListener("beforeunload", MatchGame.beforeUnload, false);
  document.removeEventListener("contextmenu", MatchGame.handleContextMenu);
  // document.removeEventListener("drop", MatchGame.dropHandler);
  // document.removeEventListener("pointerup", MatchGame.dropHandler);
}

MatchGame.games = [
  {
    name: 'Tučniaky',
    base: '/images/match_penguins/',
    hats: ["h-y.png", "h-b.png", "h-g.png"],
    scarves: ["s-o.png", "s-r.png", "s-v.png"],
    penguins: ["y-r.png", "b-r.png", "g-r.png", "y-o.png", "b-o.png", "g-o.png", "y-v.png", "b-v.png", "g-v.png"],
    baseColor: 'd6f6fa'
  }
];

MatchGame.start = function(game: any) {
  MatchGame.game = game;
  MatchGame.playing = true;
  MatchGame.draggedImg = null;
  MatchGame.draggedScarf = null;
  MatchGame.draggedHat = null;

  $("#board-wrapper").css("display", "flex");
  $("#board").css("background-color", "#" + game.baseColor + "1f");
  $("#penguins").css("background-color", "#" + game.baseColor + "1f");

  MatchGame.penguinsOrder = [...Array(game.penguins.length).keys()];
  MatchGame.hatsOrder = [...Array(game.hats.length).keys()];
  MatchGame.scarvesOrder = [...Array(game.scarves.length).keys()];
  MatchGame.shuffle();

  for (let i=0; i<game.penguins.length; i++) {
    const penImg = game.penguins[MatchGame.penguinsOrder[i]];
    
    const img = $("<img class='penguin draggable' draggable='false' ondragstart='return false;' />");
    img.attr("src", game.base + penImg);
    img.data('scarf', penImg.substring(2, 3));
    img.data('hat', penImg.substring(0, 1));
    MatchGame.draggable(img);
    
    $("#penguins").append(img);
  }

  $("#penguins .penguin:last").show();
    
  const grid = $("#grid");

  grid.append($("<div class='grid-item' />"));
  for (let i=0; i<game.hats.length; i++) {
      const hat = game.hats[MatchGame.hatsOrder[i]];
      grid.append($("<div class='grid-item img' style='background-image: url(" + game.base + hat + ")'></div>"));
    }
    for (let i=0; i<game.scarves.length; i++) {
      const scarf = game.scarves[MatchGame.scarvesOrder[i]] as string;
      grid.append($("<div class='grid-item img' style='background-image: url(" + game.base + scarf + ")'></div>"));
      for (let j=0; j<game.hats.length; j++) {
        const hat = game.hats[MatchGame.hatsOrder[j]];
        const penEl = $("<div class='grid-item img pen' />");
        grid.append(penEl);
        penEl.data("scarf", scarf.substring(2, 3));
        penEl.data("hat", hat.substring(2, 3));
      }
  }
}

MatchGame.draggable = function(peng: JQuery<HTMLElement>) {
  peng[0].addEventListener('custom-drop', (e: any) => {
      let dropX = e.detail.dropX;
      let dropY = e.detail.dropY;

      let dropTarget = $(document.elementFromPoint(dropX, dropY)!);
      let dropTile;
      if (dropTarget.is(".grid-item.img.pen")) {
          dropTile = dropTarget;
      } else if (dropTarget.parents(".grid-item.img.pen").length) {
          dropTile = dropTarget.parents(".grid-item.img.pen");
      }

      if (dropTile) {
          if (dropTile.data("scarf") === peng.data("scarf") && dropTile.data("hat") === peng.data("hat")) {
            peng.remove();
            dropTile.css("background-image", "url(" + peng.attr("src") + ")");
              $("#penguins .penguin:last").show();
              if ($("#penguins").is(":empty")) {
                MatchGame.finishAnimation();
              }
              return;
          } else {
              
          }
      }
      peng.css({"visibility": "visible", left: peng.data('originx'), top: peng.data('originy')});
  });
}

MatchGame.shuffle = function() {
  let currentIndex = MatchGame.penguinsOrder.length, randomIndex;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [MatchGame.penguinsOrder[currentIndex], MatchGame.penguinsOrder[randomIndex]] = [MatchGame.penguinsOrder[randomIndex], MatchGame.penguinsOrder[currentIndex]];
  }
  
  currentIndex = MatchGame.hatsOrder.length;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [MatchGame.hatsOrder[currentIndex], MatchGame.hatsOrder[randomIndex]] = [MatchGame.hatsOrder[randomIndex], MatchGame.hatsOrder[currentIndex]];
  }
  
  currentIndex = MatchGame.scarvesOrder.length;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [MatchGame.scarvesOrder[currentIndex], MatchGame.scarvesOrder[randomIndex]] = [MatchGame.scarvesOrder[randomIndex], MatchGame.scarvesOrder[currentIndex]];
  }
}

MatchGame.finishAnimation = function() {
  const source = document.body;
  // party.confetti(document.body, {count: party.variation.range(75, 100), lifetime: 5});

  const populated = party.util.overrideDefaults(
      {
          lifetime: 5,
          count: party.variation.range(75, 100),
          speed: party.variation.range(100, 200),
          size: party.variation.range(0.8, 1.8),
          rotation: () => new party.Vector(0, 0, party.random.randomRange(0, 360)),
          color: () => party.Color.fromHsl(50, 100, party.random.randomRange(55, 85)),
          modules: [
              new party.ModuleBuilder()
                  .drive("rotation")
                  .by((t) => new party.Vector(0, 0, 200).scale(t))
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("size")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 0 },
                          { time: 0.3, value: 1 },
                          { time: 0.7, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("opacity")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 1 },
                          { time: 0.5, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .build(),
          ],
      },
      {}
  );

  party.scene.current.createEmitter({
      emitterOptions: {
          loops: 1,
          duration: 5,
          useGravity: false,
          modules: populated.modules
      },
      emissionOptions: {
          rate: 0,
          bursts: [{ time: 0, count: populated.count }, { time: 1, count: populated.count }, { time: 2, count: populated.count }, { time: 3, count: populated.count }, { time: 4, count: populated.count }],
          sourceSampler: party.sources.dynamicSource(source),
          angle: party.variation.range(0, 360),
          initialLifetime: populated.lifetime,
          initialSpeed: populated.speed,
          initialSize: populated.size,
          initialRotation: populated.rotation,
          initialColor: populated.color
      },
      rendererOptions: {
          shapeFactory: ["star"],
          applyLighting: undefined,
      },
  });
}

export default MatchPenguins;