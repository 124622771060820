import React, { useEffect } from 'react';
import Header from '../components/Header';
import $ from "jquery";
import party from "party-js";

const MatchChosenGame: any = {};

function MatchChosen() {
    useEffect(() => {
        document.querySelector("#root")!.classList.add('game-match-chosen');
    
        $("#start").on("click", function(e) {
            const checkboxes = document.querySelectorAll('.letter-check');
            const letters = [] as string[];
            checkboxes.forEach(function(checkbox) {
                if ((checkbox as HTMLInputElement).checked) {
                    letters.push((checkbox as HTMLInputElement).value);
                }
            });
            $("#select-game").hide();
            MatchChosenGame.init(letters);
        });

        return () => {
            document.querySelector("#root")!.classList.remove('game-match-chosen');
            MatchChosenGame.dispose();
        };
  }, []);

  return <>
    <Header active="match" />
    
    <div id="select-game">
        <div>
            <input id="chosen-b" type="checkbox" className="letter-check" value="b" /> <label htmlFor="chosen-b">vybrané slová po <b>B</b></label>
        </div>
        <div>
            <input id="chosen-m" type="checkbox" className="letter-check" value="m" /> <label htmlFor="chosen-m">vybrané slová po <b>M</b></label>
        </div>
        <div>
            <input id="chosen-p" type="checkbox" className="letter-check" value="p" /> <label htmlFor="chosen-p">vybrané slová po <b>P</b></label>
        </div>
        <div>
            <input id="chosen-r" type="checkbox" className="letter-check" value="r" /> <label htmlFor="chosen-r">vybrané slová po <b>R</b></label>
        </div>
        <div>
            <input id="chosen-s" type="checkbox" className="letter-check" value="s" /> <label htmlFor="chosen-s">vybrané slová po <b>S</b></label>
        </div>
        <div>
            <input id="chosen-v" type="checkbox" className="letter-check" value="v" /> <label htmlFor="chosen-v">vybrané slová po <b>V</b></label>
        </div>
        <div>
            <input id="chosen-z" type="checkbox" className="letter-check" value="z" /> <label htmlFor="chosen-z">vybrané slová po <b>Z</b></label>
        </div>
        <button id="start" className='btn-primary' type="button">Štart</button>
    </div>

    <div id="board-wrapper" className="hidden">
        <div id="board">
            <div id="top">
                <div className="category drop" data-id="0"><div className="title">i/í</div><div className="cont"></div></div>
                <div className="category drop" data-id="1"><div className="title">y/ý</div><div className="cont"></div></div>
                <div className="shapes"></div>
            </div>
            <div id="bottom">
                <span className="points-label">Počet bodov:</span><span id="points"></span>
            </div>
        </div>
    </div>
  </>
}

MatchChosenGame.init = function(letters: string[]) {
  MatchChosenGame.game = null;
  MatchChosenGame.playing = false;
  MatchChosenGame.points = 0;
  MatchChosenGame.lastDrop = true;
  MatchChosenGame.shapeCategory = {"b/b1.png": 1, "b/b2.png": 1, "b/b3.png": 1, "b/b4.png": 0, "b/b5.png": 0, "b/b6.png": 1, "b/b7.png": 1, "b/b8.png": 1,
      "b/b9.png": 0, "b/b10.png": 0, "b/b11.png": 0, "b/b12.png": 1, "b/b13.png": 1, "b/b14.png": 1, "b/b15.png": 1, "b/b16.png": 1, "b/b17.png": 0,
      "s/s1.png": 1, "s/s2.png": 0, "s/s3.png": 1, "s/s4.png": 0, "s/s5.png": 0, "s/s6.png": 0, "s/s7.png": 0, "s/s8.png": 1,
      "s/s9.png": 1, "s/s10.png": 0, "s/s11.png": 0, "s/s12.png": 1, "s/s13.png": 0, "s/s14.png": 0, "s/s15.png": 0, "s/s16.png": 1,
      "r/r1.png": 1, "r/r2.png": 1, "r/r3.png": 0, "r/r4.png": 1, "r/r5.png": 0, "r/r6.png": 1, "r/r7.png": 0, "r/r8.png": 1,
      "r/r9.png": 1, "r/r10.png": 0, "r/r11.png": 1, "r/r12.png": 1, "r/r13.png": 0, "r/r14.png": 1, "r/r15.png": 0, "r/r16.png": 0,
      "p/p1.png": 1, "p/p2.png": 0, "p/p3.png": 0, "p/p4.png": 1, "p/p5.png": 1, "p/p6.png": 1, "p/p7.png": 1, "p/p8.png": 0,
      "p/p9.png": 1, "p/p10.png": 0, "p/p11.png": 0, "p/p12.png": 1, "p/p13.png": 0, "p/p14.png": 1, "p/p15.png": 0, "p/p16.png": 1,
      "m/m1.png": 0, "m/m2.png": 0, "m/m3.png": 0, "m/m4.png": 0, "m/m5.png": 1, "m/m6.png": 1, "m/m7.png": 0, "m/m8.png": 1,
      "m/m9.png": 1, "m/m10.png": 0, "m/m11.png": 1, "m/m12.png": 1, "m/m13.png": 1, "m/m14.png": 0, "m/m15.png": 1, "m/m16.png": 0,
      "z/z1.png": 0, "z/z2.png": 1, "z/z3.png": 1, "z/z4.png": 1, "z/z5.png": 1, "z/z6.png": 1, "z/z7.png": 0, "z/z8.png": 1,
      "z/z9.png": 0, "z/z10.png": 0, "z/z11.png": 0, "z/z12.png": 0, "z/z13.png": 0, "z/z14.png": 0,
      "v/v1.png": 0, "v/v2.png": 0, "v/v3.png": 0, "v/v4.png": 0, "v/v5.png": 1, "v/v6.png": 1, "v/v7.png": 0, "v/v8.png": 1,
      "v/v9.png": 0, "v/v10.png": 1, "v/v11.png": 1, "v/v12.png": 1, "v/v13.png": 1, "v/v14.png": 1, "v/v15.png": 1, "v/v16.png": 1,
    };
  MatchChosenGame.shapesOrder = [];

  MatchChosenGame.beforeUnload = function(e: any) {
    if (MatchChosenGame.playing) {
        e.preventDefault();
        return e.returnValue = " ";
    }
  }

  MatchChosenGame.handleContextMenu = function(e: any) {
    e.preventDefault();
    return false;
  }

  window.addEventListener("beforeunload", MatchChosenGame.beforeUnload, false);
  document.addEventListener("contextmenu", MatchChosenGame.handleContextMenu);
  $("body, #board-wrapper").on("dblclick", function(e) {
    if ($(e.target).is($("body, #board-wrapper"))) {
        const boardWrapper = $("#board-wrapper")[0].getBoundingClientRect();
        $("#board").css({width: boardWrapper.width, height: boardWrapper.height});
    }
  });

  const resImages = [] as string[];
  for (var letter of letters) {
    resImages.push(...MatchChosenGame.games[0].imagesLetters[letter]);
  }
  MatchChosenGame.games[0].images = resImages;

  MatchChosenGame.start(MatchChosenGame.games[0]);
}

MatchChosenGame.dispose = function() {
  $("body").css("background-color", "white");

  $("body, #board-wrapper").off("dblclick");
  window.removeEventListener("beforeunload", MatchChosenGame.beforeUnload, false);
  document.removeEventListener("contextmenu", MatchChosenGame.handleContextMenu);
}

MatchChosenGame.games = [
  {
    base: '/images/match_chosen/',
    imagesLetters: {b: ["b/b1.png", "b/b2.png", "b/b3.png", "b/b4.png", "b/b5.png", "b/b6.png", "b/b7.png", "b/b8.png",
            "b/b9.png", "b/b10.png", "b/b11.png", "b/b12.png", "b/b13.png", "b/b14.png", "b/b15.png", "b/b16.png",
            "b/b17.png"],
            s: ["s/s1.png", "s/s2.png", "s/s3.png", "s/s4.png", "s/s5.png", "s/s6.png", "s/s7.png", "s/s8.png",
            "s/s9.png", "s/s10.png", "s/s11.png", "s/s12.png", "s/s13.png", "s/s14.png", "s/s15.png", "s/s16.png"],
            r: ["r/r1.png", "r/r2.png", "r/r3.png", "r/r4.png", "r/r5.png", "r/r6.png", "r/r7.png", "r/r8.png",
            "r/r9.png", "r/r10.png", "r/r11.png", "r/r12.png", "r/r13.png", "r/r14.png", "r/r15.png", "r/r16.png"],
            p: ["p/p1.png", "p/p2.png", "p/p3.png", "p/p4.png", "p/p5.png", "p/p6.png", "p/p7.png", "p/p8.png",
            "p/p9.png", "p/p10.png", "p/p11.png", "p/p12.png", "p/p13.png", "p/p14.png", "p/p15.png", "p/p16.png"],
            m: ["m/m1.png", "m/m2.png", "m/m3.png", "m/m4.png", "m/m5.png", "m/m6.png", "m/m7.png", "m/m8.png",
            "m/m9.png", "m/m10.png", "m/m11.png", "m/m12.png", "m/m13.png", "m/m14.png", "m/m15.png", "m/m16.png"],
            z: ["z/z1.png", "z/z2.png", "z/z3.png", "z/z4.png", "z/z5.png", "z/z6.png", "z/z7.png", "z/z8.png",
            "z/z9.png", "z/z10.png", "z/z11.png", "z/z12.png", "z/z13.png", "z/z14.png"],
            v: ["v/v1.png", "v/v2.png", "v/v3.png", "v/v4.png", "v/v5.png", "v/v6.png", "v/v7.png", "v/v8.png",
            "v/v9.png", "v/v10.png", "v/v11.png", "v/v12.png", "v/v13.png", "v/v14.png", "v/v15.png", "v/v16.png"],
        },
    categories: ["i/í", "y/ý"],
    baseColor: 'bb7d05'
  }
];

MatchChosenGame.start = function(game: any) {
  MatchChosenGame.game = game;
  MatchChosenGame.playing = true;
  MatchChosenGame.points = 0;
  MatchChosenGame.lastDrop = true;

  $("#board-wrapper").css("display", "flex");
  $("#game-match").css("background-color", "#" + game.baseColor + "1f");
  $("#points").text("0");

  MatchChosenGame.shapesOrder = [...Array(game.images.length).keys()];
  MatchChosenGame.shuffle();

  for (let i=0; i<MatchChosenGame.shapesOrder.length; i++) {
      const shapeImgName = game.images[MatchChosenGame.shapesOrder[i]];
      const shapeImg = game.base + shapeImgName;

      const shapeEl = $("<img class='shape draggable ghost-img' draggable='false' ondragstart='return false;' src='" + shapeImg + "' />");
      shapeEl.data("category", MatchChosenGame.shapeCategory[shapeImgName]);
      MatchChosenGame.draggable(shapeEl);

      $("#board .shapes").append(shapeEl);
  }

  $("#board .shapes .shape:last").show();
  
  $("body").css("background-color", "#ffffff");
  $("#board").css("border-right", "2px dashed #000000");
}

MatchChosenGame.shuffle = function() {
  let currentIndex = MatchChosenGame.shapesOrder.length, randomIndex;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [MatchChosenGame.shapesOrder[currentIndex], MatchChosenGame.shapesOrder[randomIndex]] = [MatchChosenGame.shapesOrder[randomIndex], MatchChosenGame.shapesOrder[currentIndex]];
  }
}

MatchChosenGame.draggable = function(shape: JQuery<HTMLElement>) {
    shape[0].addEventListener('custom-drop', (e: any) => {
        let dropX = e.detail.dropX;
        let dropY = e.detail.dropY;

        let dropTarget = $(document.elementFromPoint(dropX, dropY)!);
        let dropCategory;
        if (dropTarget.is(".category.drop")) {
            dropCategory = dropTarget;
        } else if (dropTarget.parents(".category.drop").length) {
            dropCategory = dropTarget.parents(".category.drop");
        }

        if (dropCategory) {
            if (dropCategory.data("id") === shape.data("category")) {
                dropCategory.find("div.cont").append(shape.detach());
                shape.css({"visibility": "visible", "position": 'relative', left: 'auto', top: 'auto', width: 'auto', height: 'auto'});
                shape.removeClass('draggable');
                party.sparkles(shape.get(0)!, {count: party.variation.range(20, 30), lifetime: 2});
                
                if (MatchChosenGame.lastDrop) {
                    MatchChosenGame.score();
                }
                
                MatchChosenGame.lastDrop = true;
                $("#board .shapes .shape:last").show();
                return;
            } else {
                MatchChosenGame.lastDrop = false;
            }
        }
        shape.css({"visibility": "visible", left: shape.data('originx'), top: shape.data('originy')});
    });
}

MatchChosenGame.score = function() {
  MatchChosenGame.points++;
  $("#points").text(MatchChosenGame.points);
}

MatchChosenGame.finishAnimation = function() {
  const source = document.body;
  // party.confetti(document.body, {count: party.variation.range(75, 100), lifetime: 5});

  const populated = party.util.overrideDefaults(
      {
          lifetime: 5,
          count: party.variation.range(75, 100),
          speed: party.variation.range(100, 200),
          size: party.variation.range(0.8, 1.8),
          rotation: () => new party.Vector(0, 0, party.random.randomRange(0, 360)),
          color: () => party.Color.fromHsl(50, 100, party.random.randomRange(55, 85)),
          modules: [
              new party.ModuleBuilder()
                  .drive("rotation")
                  .by((t) => new party.Vector(0, 0, 200).scale(t))
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("size")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 0 },
                          { time: 0.3, value: 1 },
                          { time: 0.7, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .relative()
                  .build(),
              new party.ModuleBuilder()
                  .drive("opacity")
                  .by(
                      new party.NumericSpline(
                          { time: 0, value: 1 },
                          { time: 0.5, value: 1 },
                          { time: 1, value: 0 }
                      )
                  )
                  .through("relativeLifetime")
                  .build(),
          ],
      },
      {}
  );

  party.scene.current.createEmitter({
      emitterOptions: {
          loops: 1,
          duration: 5,
          useGravity: false,
          modules: populated.modules
      },
      emissionOptions: {
          rate: 0,
          bursts: [{ time: 0, count: populated.count }, { time: 1, count: populated.count }, { time: 2, count: populated.count }, { time: 3, count: populated.count }, { time: 4, count: populated.count }],
          sourceSampler: party.sources.dynamicSource(source),
          angle: party.variation.range(0, 360),
          initialLifetime: populated.lifetime,
          initialSpeed: populated.speed,
          initialSize: populated.size,
          initialRotation: populated.rotation,
          initialColor: populated.color
      },
      rendererOptions: {
          shapeFactory: ["star"],
          applyLighting: undefined,
      },
  });
}

export default MatchChosen;